import {
  POWER_SCHEDULES as power_schedules,
  POWER_TYPES as power_types,
} from "@/components/constants.js"

import { CameraStatus } from "@evercam/shared/types/camera"

const statuses = [
  { name: "All", value: "" },
  { name: "Under Maintenance", value: CameraStatus.UnderMaintenance },
  { name: "Offline", value: CameraStatus.Offline },
  { name: "Offline (Scheduled)", value: CameraStatus.OfflineScheduled },
]

export const POWER_SCHEDULES = [{ value: "", name: "All" }, ...power_schedules]

export const POWER_TYPES = [{ value: "", name: "All" }, ...power_types]

export default (self) => {
  let includeStatusFilter = {}
  if (self.report === "offline-cameras") {
    includeStatusFilter = {
      status: {
        component: "SelectSearchFilter",
        attributes: { items: statuses },
      },
    }
  }

  return {
    timezone: {
      component: "TextFieldSearchFilter",
    },
    ...includeStatusFilter,
    powerType: {
      component: "SelectSearchFilter",
      attributes: {
        items: POWER_TYPES,
      },
    },
    powerSchedule: {
      component: "SelectSearchFilter",
      attributes: {
        items: POWER_SCHEDULES,
      },
    },
    name: {
      component: "TextFieldSearchFilter",
      name: "cameraName",
    },
    publicNote: {
      component: "TextFieldSearchFilter",
      name: "publicNote",
      offline: true,
    },
    privateNote: {
      component: "TextFieldSearchFilter",
      name: "privateNote",
      offline: true,
    },
  }
}
